// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
//Paleta amb totes les tonalitats blaves de l'empresa
$md-blue: (
  50: #f0f6fc,
  100: #b3e1f6,
  200: #80cef1,
  300: #4dbaeb,
  400: #26abe6,
  500: #009ce2,
  600: #0094df,
  700: #008ada,
  800: #006699,
  900: #002f47,
  A100: #f6fbff,
  A200: #c3e0ff,
  A400: #90c5ff,
  A700: #77b8ff,
  //Paleta amb totes els colors negre i blanc de l'empresa
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
//Paleta amb totes les tonalitats grises de l'empresa
$md-gray: (
  50: #eaebec,
  100: #cbcecf,
  200: #b7bdbf,
  300: #848D91,
  400: #6c7376,
  500: #525a5e,
  600: #4b5256,
  700: #41484c,
  800: #383f42,
  900: #282e31,
  A100: #84cefb,
  A200: #52bbf9,
  A400: #1aa9ff,
  A700: #009fff,
  //Paleta amb totes els colors negre i blanc de l'empresa
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
//Paleta amb totes les tonalitats verdes de l'empresa
$md-mutua-green: (
  50: #e2f6ef,
  100: #b8e9d7,
  200: #88dbbd,
  300: #58cda3,
  400: #35c28f,
  500: #11b77b,
  600: #0fb073,
  700: #0ca768,
  800: #0a9f5e,
  900: #05904b,
  A100: #bdffda,
  A200: #8affbe,
  A400: #57ffa2,
  A700: #3dff94,
  //Paleta amb totes els colors negre i blanc de l'empresa
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
//Paleta amb totes les tonalitats vermelles de l'empresa
$md-mutua-red: (
  50: #fbe5e9,
  100: #f5bdc9,
  200: #ee92a5,
  300: #e76680,
  400: #e14565,
  500: #dc244a,
  600: #d82043,
  700: #d31b3a,
  800: #ce1632,
  900: #c50d22,
  A100: #fff1f3,
  A200: #ffbec4,
  A400: #ff8b95,
  A700: #ff727e,
  //Paleta amb totes els colors negre i blanc de l'empresa
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
//Paleta amb totes les tonalitats grogues de l'empresa
$md-yellow: (
  50: #fef3e4,
  100: #fde0bb,
  200: #fbcb8e,
  300: #f9b661,
  400: #f8a73f,
  500: #f7971d,
  600: #f68f1a,
  700: #f58415,
  800: #f37a11,
  900: #f1690a,
  A100: #ffffff,
  A200: #fff0e7,
  A400: #ffcfb4,
  A700: #ffbf9b,
  //Paleta amb totes els colors negre i blanc de l'empresa
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// config theme
$mutua-primary: mat-palette($md-blue);
$mutua-accent: mat-palette($md-yellow, 500);

// The warn palette is optional (defaults to red).
$mutua-warn: mat-palette($md-mutua-red, 500);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mutua-theme: mat-light-theme(
  (
    color: (
      primary: $mutua-primary,
      accent: $mutua-accent,
      warn: $mutua-warn,
    ),
  )
);
